import { RequestStatus } from "@/shared/enums";

export const useStatusName = (opts: any = {}) => {
  let tPassed = opts.t;
  if (!opts.t) {
    const { t } = useI18n();
    tPassed = t;
  }

  const getExchangeStatusName = (status: string) => {
    switch (status) {
      case RequestStatus.kUploadCard:
        return tPassed("c-exchange-reqItem-verifying-card");
      case RequestStatus.kWaitingModeratorCardCheck:
        return tPassed("c-exchange-reqItem-moderator-verifying");
      case RequestStatus.kWaitingALM:
        return tPassed("c-exchange-reqItem-waiting-alm");
      case RequestStatus.kPreparingCryptoAddress:
        return tPassed("c-exchange-reqItem-preparing-crypto");
      case RequestStatus.kPreparingFiatRequesites:
        return tPassed("c-exchange-reqItem-preparing-fiat");
      case RequestStatus.kSendingCryptoPayment:
        return tPassed("c-exchange-reqItem-sending-crypto");
      case RequestStatus.kSendingFiatPayment:
        return tPassed("c-exchange-reqItem-sending-fiat");
      case RequestStatus.kConfirningFiatPayment:
      case RequestStatus.kConfirmingCryptoPayment:
        return tPassed("c-exchange-reqItem-confirming-payment");
      case RequestStatus.kWaitingCryptoPayment:
      case RequestStatus.kWaitingFiatPayment:
        return tPassed("c-exchange-reqItem-waiting-payment");
      case RequestStatus.kRequestCompleted:
        return tPassed("c-exchange-reqItem-completed");
      case RequestStatus.kRequestFailed:
        return tPassed("c-exchange-reqItem-failed");
      case RequestStatus.kRequestCancelled:
        return tPassed("c-exchange-reqItem-cancelled");
      default:
        return status;
    }
  };

  const getP2PStatusName = (status: string) => {
    switch (status) {
      default:
        return status;
    }
  };

  return {
    getExchangeStatusName,
    getP2PStatusName,
  };
};
